<template>
  <form
    v-bind="$attrs"
    class="b-form w-full"
    @submit.prevent="onSubmit"
  >
    <slot />
    <section
      v-if="isControlsVisible"
      class="controls bg-white dark:bg-black flex flex-col sm:flex-row items-center mt-10"
    >
      <b-button
        v-if="withDefaultSubmitButton"
        type="submit"
        :color="buttonColor"
        variant="fill"
        :disabled="disabled"
        :loading="loading"
        class="w-full sm:w-auto text-center"
      >
        {{ submitButtonLabel }}
      </b-button>
      <slot name="controls" />
    </section>
  </form>
</template>

<script>
import BButton from 'common/components/BButton'

export default {
  name: 'BForm',
  components: {
    BButton,
  },
  props: {
    /**
     * Specify if should render default submit button
     */
    withDefaultSubmitButton: {
      type: Boolean,
      default: true,
    },
    /**
     * Submit button label
     */
    submitButtonLabel: {
      type: String,
      default: 'Save',
    },
    /**
     * Submit button color
     */
    buttonColor: {
      ...BButton.props.color,
      default: 'primary',
    },
    /**
     * Is submit button has disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Is submit button has loading state
     */
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isControlsVisible() {
      return this.withDefaultSubmitButton || this.$slots.controls
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit')
    },
  },
}
</script>

<style scoped>
.controls > *:not(:last-child) {
  @apply sm:mr-2;
}
</style>
