<template>
  <b-modal
    size="sm"
    @close="handleClose"
    :portal="false"
  >
    <b-form
      :submit-button-label="confirmText"
      :button-color="confirmColor"
      class="p-8"
      @submit="handleConfirm"
    >
      <div class="m-w-64">
        <div class="font-medium text-lg text-black dark:text-white mb-2">
          {{ title }}
        </div>
        <div class="text-sm text-gray-700 dark:text-gray-300" v-html="message">
        </div>
      </div>
      <slot />
      <template slot="controls">
        <b-button
          class="rtl:mr-2 mt-2 mx-2 sm:mt-0 w-full sm:w-auto text-center"
          type="button"
          variant="light"
          :color="cancelColor"
          @click="handleCancel"
        >
          {{ cancelText }}
        </b-button>
      </template>
    </b-form>
  </b-modal>
</template>

<script>
import BForm from 'common/components/BForm'
import BButton from 'common/components/BButton'
import BModal from 'common/components/BModal'

export default {
  name: 'Confirm',

  components: {
    BButton,
    BForm,
    BModal,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    confirmColor: {
      type: String,
      default: 'primary',
    },
    cancelColor: {
      type: String,
      default: 'primary',
    },
    message: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'Confirm',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    onClose: {
      type: Function,
      default: null,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
  },

  methods: {
    handleCancel(e) {
      if (this.onCancel) {
        this.onCancel(e)
      }

      this.destroy()
    },
    handleClose(e) {
      if (this.onClose) {
        this.onClose(e)
      } else if (this.onCancel) {
        this.onCancel(e)
      }

      this.destroy()
    },
    handleConfirm(e) {
      if (this.onConfirm) {
        this.onConfirm(e)
      }

      this.destroy()
    },
    destroy() {
      this.$destroy()
      this.$el.remove()
    },
  },
}
</script>
